@import "../variables";
@import "../template/mixin_templates";
@import "../../foundation/functions";

/* Général */
body{
    background-color: $body_background_color;
    color: $body_texte_color;
}
a, a:visited{
    color: $link_color;
}
a:hover, a:active{
    color: $link_hover_color;
}

h2, .h2,
.apercu-article h2.titre-article, .apercu-article .h2.titre-article{
    color: $titlesection_color;
    background-color: $titlesection_background_color;
    a, a:hover{
        color: inherit !important;
    }
}


/* Header */
header{
    background-color: $header_background_color;
}
.slogan{
    color: $slogan_color;
}

#nav-principal{
    .navigation_link_p{
        color: $nav_color;
    }
    .navigation_button_p{
        background-color: $nav_background_color;
        border-color:$nav_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color:  $nav_actif_background_color;
            border-color: $nav_actif_color;
            > .navigation_link_p{
                color: $nav_actif_color;
            }
        }
    }

    .sous_navigation_link_p{
        color: $sousnav_color;
    }
    .sous_navigation_button_p{
        background-color: $nav_actif_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $sousnav_hover_background_color;
            > .sous_navigation_link_p{
                color: $sousnav_hover_color;
            }
        }
    }
}
.wrapper{
    background-color: $content_background_color;
}

/* Content */

.content .nav-static-breadcrumb a.actif, #fiche-produit nav.secondaire a.actif{
    color: $navarianne_actif_color;
}
a.bouton, .content .button, .button, a.button, input[type=submit], a.addmsglo, a.addbasket, .diaporama a,.template_fiche_produit_3 #fiche-produit .wrap-description .col-sm-3.side-tabs a{
    background-color: $button_background_color;
    border-color: $button_border_color;
    color: $button_color;
}
.formulaires_perso input[type=submit]{
    background-color: $button_background_color !important;
    border-color: $button_border_color !important;
    color: $button_color !important;
}
.content a.bouton:hover, .button:hover, a.button:hover, a.addmsglo:hover, .addbasket:hover, .diaporama a:hover,.template_fiche_produit_3 #fiche-produit .wrap-description .col-sm-3.side-tabs a:hover,
.content a.bouton:focus, .button:focus, a.button:focus, a.addmsglo:focus, .addbasket:focus, .diaporama a:focus,.template_fiche_produit_3 #fiche-produit .wrap-description .col-sm-3.side-tabs a:focus{
    color: $button_hover_color;
    background-color: $button_hover_background_color;
}
.formulaires_perso input[type=submit]:hover{
    color: $button_hover_color !important; 
    background-color: $button_hover_background_color !important;
}

.formulaires_perso div.center, .form-newsletter .center{
    color: $formperso_titre_color;
}

/* accueil */
.slide-text > p, .slide-text > a{
    color: $slidetext_color;
    background-color: $slidetext_background_color;
}


/* boutique */
.produit_etiquette{    
    background-color: $etiquette_background_color;
    &::after{
        border-color: $etiquette_background_color transparent transparent;
    }
}
.produit_etiquette h3,
.produit_etiquette .h3{
    color: $etiquette_color;
}
.remise{
    color: $etiquette_color;
    background-color: $etiquette_background_color;
}
.produits .zoom{
    color: $produits_mask_color;
    background-color: $produits_mask_background_color;
}
.produits .zoom {
    color: $produits_zoom_color;
    background-color: $produits_zoom_background_color;
}
.produits .zoom:hover{
    color: $produits_zoom_color;
    background-color: $produits_zoom_background_color;
}
.nomprod{
    background-color: $produits_nomprod_background_color;
}
.produits .nomprod a{
    color: $produits_nomprod_color;
}
.prix, .fa-star, .fa-star-half-o, .fa-star-o{
    color: $prix_color;
}
.prix .oldprix{
    color: $oldprix_color;
}

#fiche-produit .remise-produit{
    color: $etiquette_color;
    background-color: $etiquette_background_color;
}
#fiche-produit .wrap-description .prix span.oldprix{
    color: $ficheproduit_oldprix_color;
}
.search-bar .search-bar-icon button{
    color: $searchbaricon_color;
    background-color: $searchbaricon_background_color;
}
.search-bar .search-bar-icon button:hover{
    color: $searchbaricon_background_color;
    background-color: $searchbaricon_color;
}
#fiche-produit .wrap-images .previews .previous, #fiche-produit .wrap-images .previews .next{
    color: $ficheproduit_fleches_color;
}


/* galerie */
.list-photos .galerie {
    &, .mask{
        background-color: $photo_mask_background_color;
    }
}
.list-photos .galerie p{
    color: $photo_description_color;
    background-color: $photo_description_background_color;
}
.list-photos .galerie .addcommentaires{
    color: $photo_commentaires_color;
}

/* livre d'or */
/*.message_lo, .message_lo a {
    color: $msglo_texte_color;
}
.message_lo h4{
    color: $msglo_h4_color;
    background-color: $msglo_h4_background_color;
}
*/
.message_lo .note{
    color: $msglo_note_color;
    background-color: $msglo_h4_background_color;
}

/* contact */
form input:focus, form textarea:focus, form select:focus, #fiche-produit form input:focus, 
#fiche-produit form textarea:focus, #fiche-produit form select:focus{
    box-shadow: $form_focus_shadow;
    border-color: $form_focus_bordercolor;
}


/* Footer */
footer{
    color: $footer_color;
    background-color: $footer_background_color;
}
footer .mention a, footer a{ 
    color: $footer_color;
}

/* blog */

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: $background_blog_menu;
    border-color: $background_blog_menu;
}
.pagination > li > a, .pagination > li > span{
    color:$background_blog_menu;
}
.row.two-column-blog .apercu-article h2.titre-article, .single-article .titre-article h1, .apercu-article h2.titre-article-no-image,
.row.two-column-blog .apercu-article .h2.titre-article, .single-article .titre-article .h1, .apercu-article .h2.titre-article-no-image{
    background:$background_blog_h2;
}
.pagination.pull-right li.active a{
    color:$white;
}

.template_album .list-photos .galerie .addcommentaires{
    border-color:$photo_description_color;
    color:$photo_description_color;
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: $navarianne_actif_color;
    border-color: $navarianne_actif_color;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: $navarianne_actif_color;
    color: $navarianne_actif_color;

    a, span {
        color: $navarianne_actif_color;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}

/* panier */
.template-panier{
    background-color:$panier-background-color;
    border-color:$panier-background-color;
    color: $panier-titre-color;
    &-3 .connexion p a, #header-panier{
        color: $panier-titre-color;
    }
    &-3 .connexion p{
        border-right-color: $panier-titre-color;
    }
    .count-live{
        background: $panier-content-background-color;
        color: $panier-texte-color;
    }
    #paniercontent{
        background: $panier-content-background-color;
        color: $panier-texte-color;
    }
    #paniertable{
        color: $panier-texte-color;
    }
}